import { useTranslation } from "react-i18next";
import Paragraph from "./Paragraph";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-[#0E0E2C] text-n50 py-16">
      <div className="max-w-7xl mx-auto px-6 lg:px-10">
        <div className="flex flex-col lg:flex-row gap-12 lg:gap-24">
          <div className="lg:w-1/3 flex flex-col lg:justify-center items-center lg:items-start text-center lg:text-left">
            <a href="/">
              <img
                className="w-48 cursor-pointer"
                src="/icon-white.svg"
                alt="Webby logo"
                title="Webby"
                loading="lazy"
              />
            </a>
            <Paragraph className="text-lg text-n50 mt-4 mb-6">
              {t("home.title")}
            </Paragraph>
            <div className="flex gap-6">
              <a
                href="https://www.facebook.com/webbydevs/"
                target="_blank"
                rel="noreferrer"
                className="hover:text-n0"
              >
                <img
                  src="/images/icons/facebook.svg"
                  alt={"Facebook " + t("icon")}
                  title={t("socials.facebook")}
                />
              </a>
              <a
                href="https://www.instagram.com/webbydevs/"
                target="_blank"
                rel="noreferrer"
                className="hover:text-n0"
              >
                <img
                  src="/images/icons/instagram.svg"
                  alt={"Instagram " + t("icon")}
                  title={t("socials.instagram")}
                />
              </a>
              <a
                href="https://x.com/webbydevs"
                target="_blank"
                rel="noreferrer"
                className="hover:text-n0"
              >
                <img
                  src="/images/icons/x.svg"
                  alt={t("socials.twitter_alt")}
                  title={t("socials.twitter")}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/webbydevs/"
                target="_blank"
                rel="noreferrer"
                className="hover:text-n0"
              >
                <img
                  src="/images/icons/linkedin.svg"
                  alt={"LinkedIn " + t("icon")}
                  title={t("socials.linkedin")}
                />
              </a>
            </div>
          </div>

          <div className="lg:w-2/3">
            <div className="flex flex-col md:flex-row justify-between gap-8 text-center lg:text-left">
              <div>
                <h3 className="text-n0 font-semibold text-lg mb-4">
                  {t("footer.categories.services")}
                </h3>
                <ul className="space-y-3">
                  <li>
                    <a href="/web-development" className="hover:text-n0">
                      {t("webdev.name")}
                    </a>
                  </li>
                  <li>
                    <a href="/web-applications" className="hover:text-n0">
                      {t("webapps.name")}
                    </a>
                  </li>
                  <li>
                    <a href="/ui-ux-design" className="hover:text-n0">
                      {t("uiux.name")}
                    </a>
                  </li>
                  <li>
                    <a href="/webshop-development" className="hover:text-n0">
                      {t("webshop.name")}
                    </a>
                  </li>
                  <li>
                    <a href="/maintenance" className="hover:text-n0">
                      {t("maintenance.name")}
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-n0 font-semibold text-lg mb-4">
                  {t("footer.categories.support")}
                </h3>
                <ul className="space-y-3">
                  <li>
                    <a href="/contact" className="hover:text-n0">
                      {t("contact.name")}
                    </a>
                  </li>
                  <li>
                    <a href="/prices" className="hover:text-n0">
                      {t("prices.name")}
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-n0 font-semibold text-lg mb-4">
                  {t("footer.categories.company")}
                </h3>
                <ul className="space-y-3">
                  <li>
                    <a href="/impressum" className="hover:text-n0">
                      {t("impressum.name")}
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="text-n0 font-semibold text-lg mb-4 text-nowrap">
                  {t("footer.categories.legal")}
                </h3>
                <ul className="space-y-3">
                  <li>
                    <a href="/privacy" className="hover:text-n0">
                      {t("privacy_policy.title")}
                    </a>
                  </li>
                  <li>
                    <a href="/cookies" className="hover:text-n0">
                      {t("cookie_policy.title")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-16 pt-8 border-t border-n200">
          <Paragraph className="text-sm text-n100 text-center lg:text-left">
            © {new Date().getFullYear()}{" "}
            <a href="/" className="hover:underline">
              Webby
            </a>
            . {t("all_rights_reserved")}
          </Paragraph>
        </div>
      </div>
    </footer>
  );
}
